export const environment = {
    production: true,
    basePath: 'https://qa.newpace.nbcuni.com/api/',
    campaignBasePath: 'https://qa.newpace.nbcuni.com/api/',
    adminBasePath: 'https://qa.newpace.nbcuni.com/api/',
    userBasePath: 'https://qa.newpace.nbcuni.com/api/',
    invoiceBasePath: 'https://qa.newpace.nbcuni.com/api/',
    reportsBasePath: 'http://compassreportsqa.inbcu.com/reports/report/PACE_QA/',
    environmentName: 'qa',
    name: 'QA',
    clientId: 'NBC_Media_Stage',
    sapURL: 'https://cftestuseapi.test.apimanagement.us10.hana.ondemand.com:443/fin/account_masterdata/validationv2/v2?Destination=U1Q&COMPANY_CODE=B0A1',
    sapApiKey: 'wMlHJzCOrA5qUUXZXr2KTSeqSEUNiAWo',
    authURL: 'fss.stg.inbcu.com/fss',
    appURL: 'https://qa.newpace.nbcuni.com',
    logoutURL: 'http://login.stg.inbcu.com/ssologin/logoff.jsp?referrer=',
    identityPoolId: '',
    scope: 'ExtMFAEnabled',
    powerBiUrl: 'https://app.powerbi.com/groups/0c8236c4-9996-4e15-8d66-7acf56dabe69/list?experience=power-bi',
    enableAppConfig: false,
    switchRelease: false
};
